import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import AppStoreBadge from "./AppStoreBadge.svg";
import GooglePlayBadge from "./GooglePlayBadge.png";
import Fingerprint2 from "fingerprintjs2";
import { parse } from "qs";

const useStyles = makeStyles({
  badges: {
    display: "flex",
    justifyContent: "center",

    maxWidth: "100%",
    marginTop: "5rem",
  },
  appleContainer: {
    alignSelf: "center",
  },
  apple: {
    width: "100%",
    align: "center",
  },
  google: {
    width: "150px",
  },
});

export const Fallback = () => {
  const { badges, appleContainer, apple, google } = useStyles();

  return (
    <Container>
      <div className={badges}>
        <div className={appleContainer}>
          <a href="https://testflight.apple.com/join/eWRRB9ha">
            <img className={apple} src={AppStoreBadge} alt="" />
          </a>
        </div>
        <a href="https://play.google.com/store/apps/details?id=io.rakutenadvertising.radadverrtiser2">
          <img className={google} src={GooglePlayBadge} alt="" />
        </a>
      </div>
    </Container>
  );
};

export const RADFallback = (props: any) => {
  const { badges, apple, google } = useStyles();
  const [attrData, setAttrData] = useState({});
  const location = useLocation();

  function fingerDevice() {
    setTimeout(function () {
      Fingerprint2.get(async function (components: Array<object>) {
        const finger = components.reduce((acc: any, cur: any) => {
          acc[cur.key] = cur.value;
          return acc;
        }, {});

        const ipDataPromise = await fetch(
          "https://www.cloudflare.com/cdn-cgi/trace"
        );
        const ipData = await ipDataPromise.text();
        const ip = ipData.substring(
          ipData.lastIndexOf("ip=") + 3,
          ipData.lastIndexOf("ts=") - 1
        );

        finger.ip = ip;

        const parsedQuery = parse(location.search.substring(2));
        parsedQuery.bundleId = location.pathname.substring(4);

        const storeDeviceresult = await fetch(
          "https://857b7f56c2ec.ngrok.io/device-register",
          {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ finger, parsedQuery }),
          }
        );

        window.location.assign("https://dev-attribution-sdk.web.app/fallback");

        setAttrData(finger);
      });
    }, 500);
  }

  useEffect(() => {
    fingerDevice();
  }, []);

  return (
    <Container className={badges}>
      <h2>Redirects to customer page...</h2>
    </Container>
  );
};
